import Accordion from '../../components/utilities/Accordion'

const FAQsData = [
  {
    title: 'what is bhargo capital?',
    content:
      'Bhargo Capital is a forward-thinking capital firm dedicated to driving transformative change in underdeveloped regions. We support purpose-driven entrepreneurs and innovative startups across various sectors, fostering sustainable solutions, empowerment, and technological advancements.',
  },
  {
    title: 'How can entrepreneurs or startups apply for funding from Bhargo Capital?',
    content:
      'We welcome applications from entrepreneurs and startups who align with our mission and focus areas. Please connect to us through contact methods provided in get in touch page.',
  },
  {
    title: 'What are your focus areas and investment pillars?',
    content:'Our capital firm operates across three core focus areas: Empower Innovators, Sustainable Solutions, and Innovation Hub. These pillars encompass a wide range of industries and initiatives aimed at driving positive change, innovation, and growth in underdeveloped regions.',
  },
  {
    title: 'How does Bhargo Capital support the communities it engages with?',
    content:
      'We are committed to making a meaningful impact on the ground. Through mentorship, resources, education, and sustainable practices, we empower local communities and entrepreneurs to thrive, fostering economic growth and social progress.',
  },
  {
    title: 'What sets Bhargo Capital apart from other capital firms?',
    content:
      'Bhargo Capital differentiates itself through its unwavering focus on underdeveloped regions, sustainable solutions, and purpose-driven innovation. Our holistic approach, expert team, and dedication to long-term impact set us apart as pioneers of positive change.',
  },
  {
    title: 'Can I partner with Bhargo Capital for collaborative initiatives or events?',
    content:
      'Absolutely. We are always open to collaboration and partnership opportunities that align with our mission. If you have a proposal or idea for a collaborative initiative or event, please reach out to our team for further discussion.',
  },
  {
    title: 'How do you measure the impact of your investments?',
    content:
      'We employ a comprehensive impact measurement framework that assesses both financial and social outcomes. Our goal is to not only drive profitable returns for our investors but also create meaningful, sustainable, and measurable positive change in the communities we engage with.',
  },
  {
    title: 'Do you provide mentorship and support to the startups you invest in?',
    content:
      'Yes, mentorship and support are integral parts of our approach. We offer guidance, expertise, and resources to the startups we invest in, helping them navigate challenges, refine strategies, and unlock their full potential.',
  },
  {
    title: "How can I stay updated on Bhargo Capital's initiatives and activities?",
    content:
      'You can stay informed about our latest news, events, and insights by following us on our social media channels to stay connected and engage with our ongoing efforts.',
  },
  {
    title: "How can I get in touch with Bhargo Capital for inquiries or partnership opportunities?",
    content:
      "For inquiries, partnership opportunities, or any questions you may have, please visit our 'Get in Touch' page, where you can find our contact information and reach out to our team directly.",
  },
]

const FAQsPage = () => {
  return (
    <main>
      <div className="section section-center">
        <Accordion title="Frequently Asked Questions" data={FAQsData} />
      </div>
    </main>
  )
}

export default FAQsPage
