import styled from 'styled-components'
import { NextButton } from '../components'

const values = [
  {
    title: 'Purpose beyond Profits',
    description:
      'Our commitment lies in building a better tomorrow, driven by a profound understanding that genuine prosperity emerges when wealth creation serves the greater good. We actively seek projects that align with our mission of promoting social and environmental well-being alongside financial success. By seeking this alignment, we harness the power of business to effect positive change on a broader scale.',
  },
  {
    title: 'Impact-Driven Innovation',
    description:
      ' We recognize that the most groundbreaking technologies and transformative insights often originate from unexpected sources. With an unwavering focus on innovation, we remain vigilant for trailblazing concepts and visionary thinkers who dare to challenge conventional norms. By providing support to these pioneers, we aim to catalyze pioneering advancements across various industries, propelling progress and impact.',
  },
  {
    title: 'Integrating Sustainability into Strategy',
    description:
      'Environmental stewardship is interwoven into the fabric of our investment strategy. Whether advocating for renewable energies or championing circular economics, we collaborate closely with our portfolio companies to embed sustainability within their core DNA. Our aim is to nurture long-term viability and counteract adverse externalities that could jeopardize our shared future, ensuring that our investments contribute positively to both financial and environmental well-being.',
  },
  {
    title: 'Diversity and Inclusion as Catalysts for Success',
    description:
      'Embracing diversity and inclusion is intrinsic to our pursuit of success. We actively seek out founders and teams representing a broad spectrum of backgrounds and experiences. By cultivating diversity within our organization and network, we create a rich tapestry of viewpoints that enhances our grasp of market trends, customer needs, and growth opportunities. By championing diversity and inclusion, we tap into unique insights that elevate our ability to identify compelling investments and maximize value for all stakeholders.',
  },
]

const MissionPage = () => {
  return (
    <>
      <Wrapper className="page section section-center ">
        <article className="about-page">
          <div className="title">
            <h2>our Mission</h2>
            <div className="title-underline"></div>
          </div>
          <p>
            At Bhargo Capital, our overarching vision is to empower individuals
            and communities globally through sustainable development initiatives
            and technology-driven solutions. We firmly believe in providing
            equal opportunities for success while fostering positive change on
            our planet. Through strategic investments in startups and small
            businesses that champion renewable energy sources, financial
            inclusion, and efficient resource management, we aim to stimulate
            economic growth while minimizing environmental impact. As a
            conscientious capital firm, we are dedicated to uplifting
            underserved regions, narrowing the gap between potential and
            opportunity, and collectively shaping a brighter future.
          </p>
          <p>
            Our core values underpin everything we do as we navigate the dynamic
            landscape of resources capital.
          </p>
          {values.map((value, index) => {
            return (
              <div key={index}>
                <div className="value-title"> {value.title}:</div>
                <span className="value-desc">{value.description}</span>
              </div>
            )
          })}
          <p>
            Join us in shaping this transformative path ahead and illuminating a
            future that is undeniably bright.
          </p>
        </article>
      </Wrapper>
      <NextButton url="/team" nextPageTitle="team" />
    </>
  )
}

const Wrapper = styled.section`
  text-align: justify;
  max-width: 920px;
  margin: 0 auto;
  .about-page {
    display: grid;
    gap: 1rem 2rem;
  }
  .title {
    margin: 0 auto;

    .title-underline {
      margin: 0 auto;
    }
  }
  p {
    line-height: 2;
    max-width: 100%;
    margin: 0;
    margin-top: 0.125rem;
  }
  .value-title {
    font-weight: 600;
    width: fit-content;
    border-bottom: 3px solid var(--primary-500);
  }
  .title {
    text-align: left;
  }
  .title-underline {
    margin-left: 0;
  }
`
export default MissionPage
