import React from 'react'
import styled from 'styled-components'

const Contact = () => {
  return (
    <Wrapper>
      <div className="contact-container">
        <p>Bhargo Capital</p>
        {/* <p>+61 391 231 553</p> */}
        <p>+61 3 7015 3884</p>
        <p>contact@bhargocapital.com</p>
        {/* <p>POB NO. </p> */}
        {/* <p>Level 10 / 440 Collins Street. Melbourne, Victoria, 3000, Australia.</p> */}
        <p>Suite 6.09 level 6/ 365 little Collins street, Melbourne vic 3000</p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .contact-container {
    line-height: 1;
    /* font-family: 'Courier New', Courier, monospace; */
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
`

export default Contact
