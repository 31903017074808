import { Link } from 'react-router-dom'
import styled from 'styled-components'

const SitemapPage = () => {
  return (
    <main>

      <Wrapper>
        <div className="section section-center">
          <div className="header">
            <h3 className="header-title">Sitemap</h3>
            <div className="hr-line"></div>
          </div>

          <div className="map-container">
            <h4 className="map-title">Product</h4>
            <ul className="map-links">
              <li>
                <Link to="/products">products page</Link>
              </li>
              <li>single product page</li>
              <li>add a product</li>
            </ul>
          </div>

          <div className="map-container">
            <h4 className="map-title">Trending</h4>
            <ul className="map-links">
              <li>French Gown</li>
              <li>Italian Suit</li>
              <li>Wedding Lehenga</li>
            </ul>
          </div>

          <div className="map-container">
            <h4 className="map-title">Featured</h4>
            <ul className="map-links">
              <li>French Gown</li>
              <li>Rolex Watch</li>
              <li>LV Sandals</li>
            </ul>
          </div>

          <div className="map-container">
            <h4 className="map-title">Explore</h4>
            <ul className="map-links">
              <li>Home</li>
              <li>Trending</li>
              <li>style guide</li>
              <li>help with sizing</li>
              <li>Add A Demand</li>
            </ul>
          </div>

          <div className="map-container">
            <h4 className="map-title">Registration</h4>
            <ul className="map-links">
              <li>register</li>
              <li>login</li>
            </ul>
          </div>

          <div className="map-container">
            <h4 className="map-title">About Us</h4>
            <ul className="map-links">
              <li>frugal fashion</li>
              <li>services</li>
              <li>FAQs</li>
            </ul>
          </div>

          <div className="map-container">
            <h4 className="map-title">Contact</h4>
            <ul className="map-links">
              <li>contact page</li>
              <li>Frugal Fashion Nepal pvt. ltd.</li>
            </ul>
          </div>
        </div>
      </Wrapper>
    </main>
  )
}

const Wrapper = styled.div`
  .header {
    display: flex;
    align-items: center;
    border-left: 0.2rem solid var(--primary-600);
    margin-bottom: 1rem;
    .header-title {
      margin: 0;
      padding: 0 0.5rem;
    }
    .hr-line {
      flex-grow: 1;
      background: var(--primary-600);
      height: 0.2rem;
    }
  }

  .map-container {
    background: var(--grey-50);
    margin-bottom: 0.8rem;
    padding: 1rem 0.5rem;
    .map-title {
      margin-bottom: 0.5rem;
    }
    .map-links {
      display: flex;
      flex-wrap: wrap;
      column-gap: 0.25rem;
      margin: 0;

      li {
        text-transform: capitalize;
      }

      li:not(:last-child)::after {
        content: ' | ';
      }
    }
  }

  @media (min-width: 800px) {
    .header {
      border-left: 0.3rem solid var(--primary-600);
      .header-title {
        padding: 0 0.75rem;
      }
      .hr-line {
        height: 0.25rem;
      }
    }
  }
`

export default SitemapPage
