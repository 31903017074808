import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import { Carousel } from 'react-responsive-carousel'

import hero1 from '../../assets/defi.jpg'
import hero2 from '../../assets/innovation_hub.jpg'
import hero3 from '../../assets/tomorrow-tech.jpg'
import hero4 from '../../assets/diversity.jpg'
import hero5 from '../../assets/eco-entrepreneurship.jpg'
import { styled } from 'styled-components'
import { Typewriter } from 'react-simple-typewriter'
import { BsArrowDownCircle } from 'react-icons/bs'

const HeroCarousel = ({ tagRef }) => {
  const handleScroll = (e) => {
    e.preventDefault()
    tagRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleType = (count) => {
    // access word count number
    // console.log(count)
  }

  const handleDone = () => {
    // console.log(`Done after 5 loops!`)
  }
  return (
    <Wrapper>
      <Carousel
        showThumbs={false}
        showStatus={false}
        autoPlay
        infiniteLoop
        interval={4000}
        transitionTime={1000}
      >
        <img src={hero1} className="carousel-img" alt='bhargo capital mission' />

        <img src={hero2} className="carousel-img" alt='bhargo capital mission' />

        <img src={hero3} className="carousel-img" alt='bhargo capital mission' />

        <img src={hero4} className="carousel-img" alt='bhargo capital mission' />

        <img src={hero5} className="carousel-img" alt='bhargo capital mission' />
      </Carousel>

      <div className="hero-container"></div>

      <div className="hero-text-container">
        <h2>Bhargo Capital</h2>
        <span style={{ color: 'white', fontSize: '1.25rem' }}>
          <Typewriter
            words={[
              'Empower Innovators: Igniting Potential, Sparking Change',
              'Sustainable Solutions: Revolutionizing Industries, Reshaping Tomorrow',
              'Innovation Hub: Driving Progress, Empowering Futures',
            ]}
            loop={false}
            cursor
            cursorStyle="_"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
            onLoopDone={handleDone}
            onType={handleType}
          />
        </span>
      </div>
      <div className="scroll-down-btn" onClick={handleScroll}>
        <BsArrowDownCircle />
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .hero-text {
    position: absolute;
    top: 50%;
    left: 50%;
    color: red;
  }

  .carousel-img {
    height: calc(100vh - 4rem);
    background: linear-gradient(rgba(255, 132, 39, 0.3), rgba(0, 0, 0, 0.7));
  }

  //hero text styling
  .hero-text-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--white);
  }

  //scroll down btn

  .scroll-down-btn {
    position: absolute;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    cursor: pointer;

    svg {
      font-size: 2rem;
      transition: var(--transition);
      color: var(--white);

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  .hero-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(rgba(255, 132, 39, 0.3), rgba(0, 0, 0, 0.7));
  }
`
export default HeroCarousel
