import React from 'react'
import { styled } from 'styled-components'
import { FaPhone } from 'react-icons/fa'
import { MdEmail, MdLocationOn } from 'react-icons/md'
import { DisplayLocation } from '../components'
// mailing
import { ValidationError, useForm } from '@formspree/react'
import { Link } from 'react-router-dom'

const GetInTouchPage = () => {
  const form_id = process.env.REACT_APP_FORMSPREE_FORM_ID
  const [state, handleSubmit] = useForm(form_id)

  if (state.succeeded) {
    return (
      <div className="section page" style={{ textAlign: 'center' }}>
        <h4>Thanks for contacting us!</h4>
        <Link className="btn" to={-1}>
          go back
        </Link>
      </div>
    )
  }

  return (
    <Wrapper>
      <div className="section section-center page get-in-touch-container">
        <form className="form" onSubmit={handleSubmit}>
          <h5>send us a note</h5>
          <p>
            We would love to hear from you. Please fill out the form below to
            get in touch with one of our team members.
          </p>

          <div className="form-row">
            <input
              type="text"
              className="form-input"
              placeholder="name"
              name="name"
              required
            />
            <label className="floating-label">
              name <span className="star">*</span>
            </label>
          </div>

          <div className="form-row">
            <input
              type="email"
              className="form-input"
              placeholder="email"
              name="email"
              required
            />
            <label className="floating-label">
              email <span className="star">*</span>
            </label>
          </div>

          <div className="form-row">
            <input
              type="text"
              className="form-input"
              placeholder="subject"
              name="subject"
              required
            />
            <label className="floating-label">
              subject <span className="star">*</span>
            </label>
          </div>

          <div className="form-row">
            <textarea
              rows="6"
              type="text"
              className="form-textarea"
              placeholder="Message"
              name="message"
              required
            />
          </div>

          <ValidationError prefix="Name" field="name" errors={state.errors} />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <ValidationError
            prefix="Subject"
            field="subject"
            errors={state.errors}
          />
          <ValidationError
            prefix="Message"
            field="message"
            errors={state.errors}
          />

          <button className="btn" type="submit" disabled={state.submitting}>
            submit
          </button>
        </form>

        <div className="form contact-info-container">
          <h5>contact</h5>
          <div className="contact-info">
            <FaPhone />
            {/* <span className="info">+61 391 231 553</span> */}
            <span className="info">+61 3 7015 3884</span>
          </div>
          <div className="contact-info">
            <MdEmail />
            <span className="info">contact@bhargocapital.com</span>
          </div>
          <div className="contact-info">
            <MdLocationOn />
            <span className="info">
              Suite 6.09 level 6/ 365 little Collins street, Melbourne vic 3000
              {/* LEVEL 10 / 440 COLLINS STREET. MELBOURNE, VICTORIA, 3000,
              AUSTRALIA. */}
            </span>
          </div>
        </div>
      </div>

      <DisplayLocation />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .get-in-touch-container {
    display: grid;
    gap: 2rem;
  }

  .contact-info {
    display: flex;
    align-items: center;
    column-gap: 1rem;
    svg {
      flex-shrink: 0;
      color: var(--primary-500);
    }
    .info {
      color: var(--grey-600);
    }
  }

  @media (min-width: 900px) {
    .get-in-touch-container {
      grid-template-columns: 1.5fr 1fr;
    }
  }

  //form styling
  .form {
    max-width: 40rem;
  }

  //floating label styling placeholder is mandatory in input
  .form-row {
    position: relative;
    margin-bottom: 1.5rem;

    .floating-label {
      text-transform: capitalize;
      color: var(--grey-500);
      position: absolute;
      left: 1rem;
      top: 50%;
      transform: translateY(-50%);
      transition: var(--transition);
    }
  }

  .form-input::placeholder {
    visibility: hidden;
    text-transform: uppercase;
    color: transparent;
  }

  .form-input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent;
  }

  .form-input:focus + .floating-label,
  .form-input:not(:placeholder-shown) + .floating-label,
  .form-input:focus:not(:placeholder-shown) + .floating-label {
    font-size: 0.75rem;
    color: var(--black);
    left: 0;
    top: 0;
    transform: translateY(-100%);
  }

  .star {
    color: var(--red-dark);
  }
`

export default GetInTouchPage
