import Contact from './Contact'
import Wrapper from './informationWrapper'
import { Link } from 'react-router-dom'

const TermsPage = () => {
  const name = 'Bhargo Capital'
  return (
    <main>
      <Wrapper>
        <div className="section section-center">
          <h3>Terms and Conditions</h3>

          <h5>Last Updated: 8th Aug, 2023</h5>
          <hr />

          <p>
            Welcome to {name}. By using this website, you agree to comply with and be bound by the following terms and conditions:
          </p>

          <section className="notice-segment">
            <span className='title'>Privacy Policy:</span>
            <p>
              Please refer to <Link to="/privacy">Privacy Policy</Link> to
              understand our policy regarding the data that you provide us.
            </p>
          </section>

          <section className="notice-segment">
            <span className='title'>Use of Website</span>
            <p>
              The content provided on this website is for general informational purposes only. We do not guarantee the accuracy, completeness, or reliability of the information.
            </p>
          </section>

          <section className="notice-segment">
            <span className='title'>Intellectual Property</span>
            <p>
            The content, design, graphics, and other materials on this website are protected by copyright and other intellectual property laws. 
            Unauthorized use or reproduction is strictly prohibited. Please refer to <Link to="/copyright">Copyright</Link> to
              understand our policy regarding copyright.
            </p>
          </section>

          <section className="notice-segment">
            <span className='title'>Third-Party Links</span>
            <p>
              This website may contain links to third-party websites. We are not responsible for the content or practices of any linked site.
            </p>
          </section>

          <section className="notice-segment">
            <span className='title'>Limitation of Liability</span>
            <p>
              We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of this website.
            </p>
          </section>

          <section className="notice-segment">
            <span className='title'>Governing Law</span>
            <p>
              These terms are governed by and construed in accordance with the laws of Australia, without regard to its conflict of law principles.
            </p>
          </section>

          <section className="notice-segment">
            <span className='title'>Changes to terms of use</span>
            <p>
              The terms of use may be updated as per the requirement. We will
              notify you of any changes via email and also update the effective
              from date on the page.
            </p>
          </section>

          <p>
            By using this website, you acknowledge that you have read, understood, and agree to these terms. If you do not agree with these terms, please do not use the website.
          </p>

          <section className="notice-segment">
            <span className='title'>Contact Us</span>
            <p>
              If you have any questions or concerns regarding these terms and
              conditions, please contact us at{' '}
              <span className="highlight">contact@frugalfashion.com</span>
            </p>
          </section>
          <Contact />
        </div>
      </Wrapper>
    </main>
  )
}

export default TermsPage
