import Contact from './Contact'
import Wrapper from './informationWrapper'

const CopyrightPage = () => {
  const name = 'Bhargo Capital'

  return (
    <main>
      <Wrapper>
        <div className="section section-center">
          <h3>Copyright</h3>
          <p>
            All content on this website, including text, graphics, logos,
            images, and software, is the property of our company or our
            licensors and is protected by copyright laws. You may not use or
            reproduce any content on this website without our permission. All
            the content included in or made available through any of our
            services is the exclusive property of{' '}
            <span className="highlight">{name}</span> and protected by international Copyright Laws.
          </p>
          <p>
            Permission is required for any use or reproduction of the content, and requests can be made by contacting{' '} 
            <span className="highlight">contact@bhargocapital.com</span>.
            While we strive to provide accurate and reliable information, the content on this website is for general informational purposes only 
            and should not be construed as professional advice. {name} is not responsible for the accuracy, completeness, or reliability of the content.
          </p>
          <p>
            By accessing and using this website, you agree to comply with the terms of this Copyright Notice.
            For any inquiries or concerns, please reach out to <span className="highlight">contact@bhargocapital.com</span>.
          </p>
          <Contact />
          <div>
            Copyright &copy; 2023{' '}
            <span className="highlight">{name}</span>. All rights
            reserved
          </div>
        </div>
      </Wrapper>
    </main>
  )
}

export default CopyrightPage
