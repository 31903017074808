import styled from 'styled-components'

const Wrapper = styled.div`
  line-height: 1.6rem;
  text-align: justify;

  .highlight {
    color: var(--primary-500);
  }

  .title {
    font-weight: 600;
  }

  li {
    margin: 0.5rem 1rem;
    &::before {
      content: '- ';
    }
  }
`

export default Wrapper
