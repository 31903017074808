import React, { useEffect, useState } from 'react'
import { styled } from 'styled-components'
import {
  BsArrowLeftCircle,
  BsArrowRightCircle,
} from 'react-icons/bs'
// BsArrowRight
import youth from '../assets/youth.jpg'
import diversity from '../assets/diversity.jpg'
import knowledge from '../assets/knowledge.jpg'
import { Link, useLocation } from 'react-router-dom'

//innovation
import marketplaces from '../assets/marketplace.jpg'
import tomorrowTech from '../assets/tomorrow-tech.jpg'
// import fintech from '../assets/fintech.jpg'
// import fintech from '../assets/crypto-currency.jpg'
import fintech from '../assets/pexels-worldspectrum-844124-removebg-preview.png'

//sustainable
import greenEnergy from '../assets/green-energy.jpg'
import eco from '../assets/eco-entrepreneurship.jpg'
import resourceEfficiency from '../assets/resource-efficiency.jpg'

const empoweringInnovators = {
  title: 'Empowering Innovators',
  description:
    'Reimagining the landscape of emerging economies by kindling the flames of innovation. We believe in nurturing the potential of young visionaries, empowering them to redefine industries, and driving local transformation through bold ideas and entrepreneurial spirit.',
  subAreas: [
    {
      title: 'Youth Empowerment',
      description:
        'We champion the vigor of young minds, fostering a supportive ecosystem where youthful ingenuity thrives. Through mentorship, resources, and guidance, we are dedicated to kindling the flames of innovation and enabling the young visionaries of today to become the trailblazers of tomorrow.',
      // image:'https://images.unsplash.com/photo-1618005182384-a83a8bd57fbe?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1064&q=80'
      image: youth,
    },
    {
      title: 'Diversity in Business:',
      description:
        "We're committed to breaking down barriers and promoting inclusivity in entrepreneurship. By empowering women entrepreneurs and embracing diverse talents, we forge a path towards balanced, impactful, and dynamic business projects that contribute to both economic growth and social progress.",
      image: diversity,
    },
    {
      title: 'Knowledge Amplification',
      description:
        'Education is the cornerstone of innovation. Through workshops, training programs, and accessible resources, we empower aspiring entrepreneurs with the knowledge and skills they need to navigate the complex world of business, turning their ideas into impactful realities.',
      image: knowledge,
    },
  ],
  themeColor: 'lightblue',
  prevUrl: '/about/innovation',
  nextUrl: '/about/sustainable',
}

const sustainableSolutions = {
  title: 'Sustainable Solutions',
  description:
    'Sparking an eco-revolution that redefines industries, one sustainable solution at a time. We envision a future where renewable energy, resource efficiency, and circular economies lead the charge towards a greener and more prosperous world.',
  subAreas: [
    {
      title: 'Green Energy Transformation',
      description:
        'Embracing the power of renewables, we support startups dedicated to harnessing solar and hydro energy. By promoting clean and efficient energy solutions, we contribute to reducing our carbon footprint and safeguarding the planet for generations to come.',
      image: greenEnergy,
    },
    {
      title: 'Resource Efficiency',
      description:
        'Our commitment to sustainable practices extends to responsible resource utilization. By fostering innovation in waste reduction, circular economies, and efficient local sourcing, we aim to create a more harmonious balance between industry and environment.',
      image: resourceEfficiency,
    },
    {
      title: 'Eco-Entrepreneurship',
      description:
        'We celebrate the spirit of eco-entrepreneurship, supporting startups that prioritize environmental and social impact alongside profitability. By nurturing businesses with a conscience, we drive a movement towards sustainable economic growth and societal well-being.',
      image: eco,
    },
  ],
  themeColor: '#63E1A9',
  prevUrl: '/about/empower',
  nextUrl: '/about/innovation',
}

const innovationHub = {
  title: 'Innovation Hub',
  description:
    "Pioneering progress through innovation, we reimagine technology's role in shaping communities and economies. By supporting impactful solutions and bridging gaps, we empower individuals, businesses, and societies to thrive in an ever-evolving landscape.",
  subAreas: [
    {
      title: 'Fintech Revolution',
      description:
        // "We're at the forefront of reshaping financial landscapes. By partnering with fintech disruptors, we promote financial inclusion, enhance accessibility, and drive innovation that transforms financial systems to serve individuals and businesses better.",
        'At the forefront of reshaping financial landscapes, we collaborate with fintech disruptors to promote financial inclusion, enhance accessibility, and drive innovation, revolutionizing financial systems to better serve individuals and businesses. This transformational journey is further empowered by our active engagement with blockchain technology, cryptocurrencies, and the realm of DeFi (Decentralized Finance). These advancements amplify transparency, efficiency, and decentralization, propelling the evolution of the financial sector.',
      image: fintech,
    },
    {
      title: 'Tech for Tomorrow',
      description:
        "Harnessing the power of technology, we catalyze innovation across diverse sectors. From education and healthcare to community development, our partnerships with tech-driven startups redefine possibilities and enable positive change where it's needed most.",
      image: tomorrowTech,
    },
    {
      title: 'Marketplace Dynamics',
      description:
        'We believe in the strength of interconnectedness. By fostering partnerships, marketplaces, and collaboration platforms, we empower individuals and businesses to thrive in an evolving commercial landscape, ultimately driving progress and sustainable growth.',
      image: marketplaces,
    },
  ],
  themeColor: '#FFC166',
  prevUrl: '/about/sustainable',
  nextUrl: '/about/empower',
}

const AboutPage = () => {
  const location = useLocation()
  const [currentPageData, setCurrentPageData] = useState(empoweringInnovators)

  useEffect(() => {
    if (location.pathname === '/about/empower') {
      setCurrentPageData(empoweringInnovators)
    }
    if (location.pathname === '/about/sustainable') {
      setCurrentPageData(sustainableSolutions)
    }
    if (location.pathname === '/about/innovation') {
      setCurrentPageData(innovationHub)
    }
  }, [location])

  return (
    <Wrapper theme={currentPageData.themeColor}>
      <div className="focus-area">
        <div className="head">
          <Link className="left-arrow" to={currentPageData.prevUrl}>
            <BsArrowLeftCircle />
          </Link>
          <div className="head-container">
            <div className="title">
              <h3>{currentPageData.title}</h3>
            </div>
            <div className="description">
              <p>{currentPageData.description}</p>
            </div>
          </div>

          <Link className="right-arrow" to={currentPageData.nextUrl}>
            <BsArrowRightCircle />
          </Link>
        </div>
        <div className="body section-center">
          {currentPageData.subAreas.map((subarea, index) => {
            return (
              <div
                className={`${
                  index % 2 === 0 ? 'sub-area even' : 'sub-area odd'
                }`}
                key={index}
              >
                <div className="area-img">
                  <img src={subarea.image} alt={subarea.title} />
                </div>
                <div className="area-info">
                  <div className="sub-area-name">
                    {index + 1}. <h3>{subarea.title}</h3>
                  </div>
                  <div className="sub-area-desc">
                    <p>{subarea.description}</p>
                  </div>
                  {/* <button className="outline-btn">
                    Learn More <BsArrowRight />
                  </button> */}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  --clr-blue: #85c6dc;
  --clr-orange: #ffc166;
  --clr-green: #63e1a9;
  --clr-body: ${(props) => props.theme || '#FFC166'};
  .focus-area {
    .head {
      background-color: var(--clr-body);
      display: grid;
      column-gap: 1rem;
      grid-template-columns: auto 1fr auto;
      align-items: center;
      font-size: 2.5rem;
      color: var(--grey-900);
      padding: 1rem;
      min-height: 20rem;

      .head-container {
        display: grid;
        gap: 1rem 1.5rem;
        align-items: center;
        max-width: var(--max-width);
        margin: 0 auto;

        .title {
          text-align: left;
          h3 {
            font-size: 1.5rem;
            font-weight: 400;
            text-decoration: underline;
          }
        }

        .description {
          font-size: 1rem;
          text-align: justify;
        }
      }
      svg {
        color: var(--black);
      }
    }

    .body {
      margin: 0 auto;
      .sub-area {
        padding: 1rem;

        .area-img {
          width: 95%;
          position: relative;
          height: 450px;
          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
          &::before {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            background-color: var(--clr-body);
            transform: translate(6%, 7%);
            z-index: -1;
          }
        }

        .area-info {
          padding: 0 2rem;
          .sub-area-name {
            margin-top: 3rem;
            h3 {
              font-size: 1.25rem;
              font-weight: 500;
            }
          }
          .outline-btn {
            display: flex;
            font-family: monospace;
            gap: 0.5rem;
            padding: 0 0.75rem;
            align-items: center;
            height: 2rem;
            background-color: white;
            border-radius: 1rem;
            color: var(--grey-700);
            border: 1px solid var(--grey-900);
            cursor: pointer;

            &:hover {
              background-color: var(--grey-900);
              color: white;
            }
          }
        }
      }
    }
  }

  .sub-area-desc {
    text-align: justify;
  }

  @media (min-width: 768px) {
    .focus-area {
      font-size: 1.25rem;
      .head {
        padding: 3rem 1rem;
        .head-container {
          grid-template-columns: 1fr 1fr;

          .title {
            h3 {
              font-size: 3.5rem;
              font-weight: 400;
            }
          }

          .description {
            font-size: 1.25rem;
          }
        }
      }
      .body {
        .sub-area {
          display: grid;
          gap: 1rem;
          margin: 2rem 1.25rem;

          .area-img {
            grid-area: area-img;
            width: 100%;
          }
          .area-info {
            padding: 0 2rem;
            grid-area: area-info;
            .sub-area-name {
              margin-top: 3rem;
              h3 {
                font-size: 1.8rem;
                font-weight: 600;
              }
            }
          }
        }

        .even {
          grid-template-columns: 3fr 4fr;
          grid-template-areas: 'area-info area-img';
        }

        .odd {
          grid-template-columns: 4fr 3fr;
          grid-template-areas: 'area-img area-info';
          .area-img {
            &::before {
              transform: translate(-6%, 7%);
            }
          }
        }
      }
    }
  }
`

export default AboutPage
