import { Breadcrumbs, HeaderTags } from '../../components'
import Contact from './Contact'
import Wrapper from './informationWrapper'

const PrivacyPolicyPage = () => {
  const website = 'bhargocapital.com'

  return (
    <main>
      <Wrapper>
        <div className="section section-center">
          <h3>Privacy Policy</h3>

          <h5>Last Updated: 8th Aug, 2023</h5>
          <hr />

          <p>
            At Bhargo Capital, we value your privacy and are committed to safeguarding your personal information.
            This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use
            our website <span className="highlight">{website}</span>.
          </p>
          <p>
            By using the Service, you agree to the terms outlined in this Privacy Policy. If you do not agree with the practices described here, please do not use the Service.
          </p>

          <section className="notice-segment">
            <h4>Information Collection and Use</h4>
            <p>
              We may collect several types of information for various purposes, including but not limited to:
            </p>
            <ul>
              <li>
                Personal Data:
                When you interact with our website, you may voluntarily provide us with personal information, 
                such as your name, email address, and contact details. We use this information to communicate with you and provide relevant updates.
              </li>
              <li>
                Usage Data:
                We may collect data about your interactions with the Service, including your IP address, browser type, operating system, and pages viewed.
                This information helps us analyze trends, administer the site, and improve user experience.
              </li>
            </ul>
            <p>
              We may collect this information directly from you, or from the
              third-party sources such as social media platforms
            </p>
          </section>

          <section className="notice-segment">
            <h4>Use of Your Information</h4>
            <p>
              We may use your personal information for the following purposes:
            </p>
            <ul>
              <li>To provide and maintain the Service</li>
              <li>
                To communicate with you and respond to your inquiries
              </li>
              <li>
                To analyze and improve the Service's performance
              </li>
              <li>
                To comply with legal obligations
              </li>
            </ul>
            <p>
              We may also use the provided information for other purposes with
              the users permission
            </p>
          </section>

          <section className="notice-segment">
            <h4>Information Sharing and Disclosure</h4>
            <p>
              We may share your personal information with third parties under certain circumstances, including:
            </p>
            <ul>
              <li>
                Service Providers:
                We may engage third-party companies and individuals to assist with the Service's operation and maintenance.
                These service providers may have access to your personal information to perform tasks on our behalf.
              </li>
              <li>
                Analytics partners who help us improve your user experience and
                measure the performance of our websites and tools
              </li>
              <li>
                Legal authorities as required by the law or to protect ours as
                well as users rights and property(s)
              </li>
              <li>
                We do not sell or rent the users information without their
                explicit consent.
              </li>
            </ul>
            <p>
              We may also share information with other third parties with the
              users permission
            </p>
          </section>

          <section className="notice-segment">
            <h4>Data Security</h4>
            <p>
              We implement appropriate security measures to protect your personal information from unauthorized access, alteration, 
              disclosure, or destruction. 
              However, please note that no method of transmission over the internet is completely secure, and we cannot guarantee absolute security.
            </p>
          </section>

          <section className="notice-segment">
            <h4>Changes to this Privacy Policy</h4>
            <p>
              We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on this page.
            </p>
          </section>

          <section className="notice-segment">
            <h4>Contact Us</h4>
            <p>
              If you have any questions or concerns regarding this privacy
              policy or use of your personal information, please contact us at{' '}
              <span className="highlight">contact@bhargocapital.com</span>
            </p>
          </section>
          <p>
            By using the Service, you acknowledge that you have read and understood this Privacy Policy and agree to its terms.
          </p>
          <Contact />
        </div>
      </Wrapper>
    </main>
  )
}

export default PrivacyPolicyPage
