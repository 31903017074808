import { Link } from 'react-router-dom'
import { styled } from 'styled-components'

const NextButton = ({ nextPageTitle = 'page title', url = '/' }) => {
  return (
    <Wrapper>
      <div className="section section-center">
        <div className="next-button-container">
          <div className="next">Next</div>
          <Link to={url} className="page-title">
            {nextPageTitle}
            <div className="underline"></div>
          </Link>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: var(--grey-50);
  .next-button-container {
    text-align: center;
    padding: 4rem 0;

    .next {
      font-size: 1.5rem;
      font-weight: 300;
    }
    .page-title {
      cursor: pointer;
      text-transform: capitalize;
      font-size: 3rem;
      display: inline-block;
    }
  }

  //underline animation
  .underline {
    width: 0%;
    height: 2px;
    background-color: var(--black);
    transition: var(--transition);
  }

  .page-title {
    &:hover {
      .underline {
        width: 100%;
      }
    }
  }
`

export default NextButton
