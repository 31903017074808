import { useRef, useState } from 'react'
import { BsChevronDown } from 'react-icons/bs'
import styled from 'styled-components'

const accordionData = [
  {
    title: 'Question 1',
    content:
      'Lorem Ipsum',
  },
  {
    title: 'Question 2',
    content:
      'Lorem Ipsum',
  },
]

const AccordionItem = ({ item }) => {
  const [active, setActive] = useState(false)

  const handleToggle = () => {
    setActive((prev) => !prev)
  }

  const contentEL = useRef()

  return (
    <Wrapper>
      <li className="accordion-item">
        <div
          className={active ? 'header active-header' : 'header'}
          onClick={handleToggle}
        >
          {item.title}
          <button className={active ? 'btn-icon active' : 'btn-icon'}>
            <BsChevronDown />
          </button>
        </div>
        <div
          ref={contentEL}
          className="answer-container"
          style={
            active
              ? { height: contentEL.current.scrollHeight }
              : { height: '0px' }
          }
        >
          <p className="answer">{item.content}</p>
        </div>
      </li>
    </Wrapper>
  )
}

const Accordion = ({ title = 'Accordion Title', data = accordionData }) => {
  return (
    <Wrapper>
      <div className="accordion-container">
        <h3 className="accordion-title">{title}</h3>
        <ul className="accordion">
          {data.map((item, index) => (
            <AccordionItem item={item} key={index} />
          ))}
        </ul>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .accordion-container {
    max-width: 768px;
    margin: 0 auto;
    .accordion-title {
      text-align: center;
    }
  }

  .accordion {
    box-shadow: var(--shadow-2);

    .header {
      text-transform: capitalize;
      border: 1px solid var(--grey-200);
      width: 100%;
      padding: 0.65rem;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      color: var(--grey-700);
      transition: var(--transition);
      &:hover {
        background: var(--grey-100);
      }
    }

    .active-header {
      color: var(--primary-700);
      background: var(--primary-100);
      &:hover {
        background: var(--primary-100);
      }
    }

    .btn-icon {
      color: var(--grey-400);
    }

    .active {
      transform: rotate(-180deg);
      transition: var(--transition);
      color: var(--primary-700);
    }

    .answer-container {
      border: 1px solid var(--grey-200);
      border-top: none;
      border-bottom: none;
      overflow: hidden;
      transition: var(--transition);
      background: var(--grey-50);
      .answer {
        margin: 0;
        padding: 0.5rem 1rem;
      }
    }
  }
`

export default Accordion
