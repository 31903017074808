import { styled } from 'styled-components'
import { FieldOfOperation, HeroCarousel, NextButton } from '../components'
import { useRef } from 'react'

const LandingPage = () => {
  const tagRef = useRef(null)
  return (
    <>
      <HeroCarousel tagRef={tagRef} />

      <Wrapper ref={tagRef}>
        <div className="tag-section section section-center">
          <div className="tag-text ">
            <div className="tag-line">The future is bright.</div>
            <p className="tag-description">
              Welcome to a future that shines brighter with purpose. At Bhargo
              Capital, we're not just investors; we're partners in visionary
              transformation. Through our three pillars—Empower Innovators,
              Sustainable Solutions, and Innovation Hub—we reshape industries
              with breakthrough technologies and purpose-driven innovation. The
              future is bright, and together, we illuminate it.
            </p>
          </div>
        </div>
      </Wrapper>
      <FieldOfOperation />
      <NextButton nextPageTitle="about" url="/about" />
    </>
  )
}

const Wrapper = styled.div`
  padding: 4rem 0;
  .tag-section {
    background-color: var(--grey-50);
  }
  .tag-text {
    display: grid;
    column-gap: 2rem;
    padding: 2rem;
    align-items: center;

    .tag-line {
      font-size: 1.85rem;
      text-decoration: underline;
    }
    .tag-description {
      max-width: 30rem;
      text-align: justify;
    }
  }

  @media (min-width: 786px) {
    .tag-text {
      grid-template-columns: 1fr 1fr;

      .tag-line {
        font-size: 3rem;
        max-width: 10rem;
        text-align: right;
        justify-self: flex-end;
      }
    }
  }
`


export default LandingPage
