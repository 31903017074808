import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { styled } from 'styled-components'

import bhargoEducation from '../assets/projects/bhargo-educaction.png'
import techconnect from '../assets/projects/tech_connect.png'
import educhampion from '../assets/projects/educhampion.png'
import green_tech from '../assets/projects/green_tech.png'
import eco_sprout from '../assets/projects/eco_sprout.png'
import solar_wings from '../assets/projects/solar_wings.png'
import aqua_pulse from '../assets/projects/aqua_pulse.png'
import bio_harvest from '../assets/projects/bio_harvest.png'
import water_spark from '../assets/projects/water_spark.png'
import finnex from '../assets/projects/finnex.png'
import market_link from '../assets/projects/market_link.png'
import trade_connect from '../assets/projects/trade_connect.png'
import { NextButton } from '../components'
import { themeColors } from '../utils/constants'

const filters = [
  {
    buttonText: 'all',
    url: '/portfolio',
    tagline:
      'Illuminating pathways of progress, ushering transformative change across industries through purpose-driven innovation.',
    theme: '#e3e2fb',
  },
  {
    buttonText: 'empowering innovators',
    url: '/portfolio?filter=empowering-innovators',
    tagline:
      'Igniting the potential of emerging entrepreneurs, driving meaningful change by empowering visionary leaders.',
    theme: themeColors[0],
  },
  {
    buttonText: 'sustainable solutions',
    url: '/portfolio?filter=sustainable-solutions',

    tagline:
      'Fostering a greener tomorrow, propelling sustainable technologies that harmonize industry and environment.',

    theme: themeColors[1],
  },
  {
    buttonText: 'innovation hub',
    url: '/portfolio?filter=innovation-hub',

    tagline:
      'Nurturing innovation ecosystems, connecting dreamers and doers to reshape commerce and amplify progress.',

    theme: themeColors[2],
  },
]

const projects = [
  // empower innovators
  {
    name: 'Bhargo Education',
    desc: "Shaping peoples' life through bhargo education.",
    logo: bhargoEducation,
    footerText: '',
    category: 'empowering-innovators',
  },

  {
    name: 'Tech Connect',
    desc: 'Incubating local startups, propelling tech-driven solutions and global growth.',
    logo: techconnect,
    footerText: '',
    category: 'empowering-innovators',
  },

  {
    name: 'EduChampion',
    desc: 'Equipping future leaders through accessible, engaging online education platforms.',
    logo: educhampion,
    footerText: '',
    category: 'empowering-innovators',
  },
  {
    name: 'GreenTech Solutions',
    desc: 'Driving underdeveloped regions with sustainable tech, fostering growth and education.',
    logo: green_tech,
    footerText: '',
    category: 'empowering-innovators',
  },
  {
    name: 'EcoSprout',
    desc: 'Advancing sustainable agriculture for prosperity in underserved communities.',
    logo: eco_sprout,
    footerText: 'ACQUIRED',
    category: 'empowering-innovators',
  },
  // sustainable solutions
  {
    name: 'SolarWings',
    desc: 'Leading solar energy adoption for a brighter, greener future in remote areas.',
    logo: solar_wings,
    footerText: '',
    category: 'sustainable-solutions',
  },
  {
    name: 'AquaPulse',
    desc: 'Pioneering clean water solutions, quenching progress and well-being in scarcity.',
    logo: aqua_pulse,
    footerText: 'ACQUIRED',
    category: 'sustainable-solutions',
  },
  {
    name: 'BioHarvest',
    desc: 'Integrating eco-tech for thriving sustainable agriculture, food security, and economies.',
    logo: bio_harvest,
    footerText: 'ACQUIRED',
    category: 'sustainable-solutions',
  },
  {
    name: 'Water Spark',
    desc: 'Providing clean, affordable water through advanced filtration for underserved areas.',
    logo: water_spark,
    footerText: 'ACQUIRED',
    category: 'sustainable-solutions',
  },
  // innovation hub
  {
    name: 'FinNex',
    desc: 'Fostering financial inclusion through secure digital banking, driving economic empowerment.',
    logo: finnex,
    footerText: '',
    category: 'innovation-hub',
  },
  {
    name: 'MarketLink',
    desc: 'Bridging local markets, empowering small businesses for sustainable livelihoods.',
    logo: market_link,
    footerText: '',
    category: 'innovation-hub',
  },
  {
    name: 'TradeConnect',
    desc: 'Empowering artisans, weaving opportunity through innovative global e-commerce.',
    logo: trade_connect,
    footerText: '',
    category: 'innovation-hub',
  },
]

const PortfolioPage = () => {
  const [subHeaderText, setSubHeaderText] = useState(filters[0].tagline)
  const [backgroundTheme, setBackgroundTheme] = useState(filters[0].theme)
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleClick = ({ url, tagline, theme }) => {
    setSubHeaderText(tagline)
    console.log(theme)
    setBackgroundTheme(theme)
    navigate(url)
  }

  useEffect(() => {
    // console.log(searchParams.get('filter'))
  }, [searchParams])

  return (
    <Wrapper style={{ background: backgroundTheme }}>
      <div className="section section-center page-100">
        <div className="portfolio-container">
          <div className="portfolio-header">
            <div className="header-btn-container">
              {filters.map((filter, index) => {
                const { url, theme, tagline, buttonText } = filter
                return (
                  <div
                    key={index}
                    className={`header-btn ${
                      location.pathname + location.search === url
                        ? 'active-btn'
                        : ''
                    }`}
                    onClick={() =>
                      handleClick({
                        url,
                        theme,
                        tagline,
                      })
                    }
                  >
                    {buttonText}
                    <div className="underline"></div>
                  </div>
                )
              })}
            </div>
            <div className="portfolio-sub-header">{subHeaderText}</div>
          </div>

          <div className="portfolio-projects-container">
            {projects
              .filter(
                (element) =>
                  !searchParams.get('filter') ||
                  element.category === searchParams.get('filter')
              )
              .map((project, index) => {
                const { name, desc, logo, footerText } = project
                return (
                  <div key={index} className="single-project">
                    <div className="project-logo">
                      <img src={logo} alt={name} className="logo" />
                    </div>

                    <div className="info-container">
                      <div className="body-text">
                        <div className="project-name">{name}</div>
                        <div className="project-desc">{desc}</div>
                      </div>
                      {footerText && (
                        <div className="footer-text">{footerText}</div>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
      <NextButton url="/our-mission" nextPageTitle="mission" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: var(--primary-50);
  transition: var(--transition);

  .header-btn-container {
    text-transform: uppercase;
    display: flex;
    align-items: center;
    gap: 0.5rem 1rem;
    flex-wrap: wrap;
    justify-content: center;
    cursor: pointer;

    .header-btn {
      color: var(--grey-600);
      &:hover {
        .underline {
          width: 100%;
        }
      }
    }
    .active-btn {
      color: var(--grey-900);
      .underline {
        width: 100%;
      }
    }

    &:hover {
    }
  }

  .portfolio-sub-header {
    margin: 1rem;
    text-align: center;
  }

  //projects styling
  .portfolio-projects-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    gap: 1rem;
  }
  .single-project {
    position: relative;
    height: 14rem;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:hover {
    }
  }
  .project-logo {
    width: 50%;
  }

  .info-container {
    .body-text {
      padding: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      opacity: 0;
      background-color: var(--primary-200);
      transition: var(--transition);
      .project-name {
        font-weight: 500;
      }
      .project-desc {
        font-size: 0.75rem;
        font-weight: 300;
      }
    }

    .footer-text {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0.35rem 0;
      text-align: center;
      transition: var(--transition);
      background-color: var(--primary-200);
    }
  }

  .single-project {
    &:hover {
      .body-text {
        opacity: 1;
      }
      .footer-text {
        background-color: var(--white);
      }
    }
  }
`

export default PortfolioPage
