export const links = [
  {
    id: 1,
    url: '/',
    text: 'home',
  },
  {
    id: 2,
    url: '/about',
    text: 'about',
    subLinks: [
      {
        text: 'empowering innovators',
        url: '/about/empower',
      },
      {
        text: 'sustainable solutions',
        url: '/about/sustainable',
      },
      {
        text: 'innovation hub',
        url: '/about/innovation',
      },
    ],
  },
  {
    id: 3,
    url: '/our-mission',
    text: 'mission',
  },
  {
    id: 4,
    url: '/team',
    text: 'team',
  },
  {
    id: 5,
    url: '/portfolio',
    text: 'portfolio',
  },

  {
    id: 7,
    url: '/get-in-touch',
    text: 'get in touch',
  },
]

export const themeColors = ['#85c6dc', '#63e1a9', '#ffc166']
