import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { Icon } from 'leaflet'

const customIcon = new Icon({
  iconUrl: require('../assets/pin.png'),
  iconSize: [38, 38],
})

const defaultLocation = {
  locationText: 'Suite 6.09 level 6/ 365 little Collins street, Melbourne vic 3000',
  // locationText: 'Level 10 / 440 Collins Street. Melbourne, Victoria, 3000, Australia.',
  coordinates: [-37.81684604098385, 144.9597365846542],
}

const DisplayLocation = () => {
  const markerPosition = defaultLocation.coordinates

  return (
    <main className="section section-center">
      <h5>find us at</h5>

      <MapContainer
        center={markerPosition}
        zoom={13}
        style={{
          width: '90%',
          height: '20rem',
          zIndex: '1',
          margin: 'auto',
          marginTop: '0.5rem',
        }}
        // markerZoomAnimation={true}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker position={markerPosition} icon={customIcon}>
          <Popup>
            <p>{defaultLocation.locationText} </p>
          </Popup>
        </Marker>
      </MapContainer>
    </main>
  )
}

export default DisplayLocation
