import { Link } from 'react-router-dom'
import styled from 'styled-components'

const ErrorPage = () => {
  return (
    <Wrapper className="section section-center page">
      <h5>Error... Page not found.</h5>
      <Link to={'/'} className="btn">
        go back
      </Link>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  text-align: center;
  vertical-align: center;
`

export default ErrorPage
