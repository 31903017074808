import { styled } from 'styled-components'
import mainImg from '../assets/brainstorming.jpg'
import img1 from '../assets/empower-innovators.jpg'
import img2 from '../assets/sustainable-solutions.jpg'
import img3 from '../assets/innovation_hub.jpg'

import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { themeColors } from '../utils/constants'

const fieldOfOperationData = [
  {
    title: 'empowering innovators',
    description:
      'Fueling the aspirations of emerging entrepreneurs from underdeveloped nations, propelling them towards global recognition and local transformation.',
    url: '/about/empower',
    themeColor: themeColors[0],
    image: img1,
  },
  {
    title: 'sustainable solutions',
    description:
      'Championing eco-friendly advancements that revolutionize industries, paving the way for resource-efficient technologies, renewable energy solutions, and waste reduction strategies.',
    url: '/about/sustainable',
    themeColor: themeColors[1],
    image: img2,
  },
  {
    title: 'innovation hub',
    description:
      'Harnessing the potential of technology and innovation to drive social progress, economic growth, and sustainability, while empowering underserved communities and businesses.',
    url: '/about/innovation',
    themeColor: themeColors[2],
    image: img3,
  },
]

const defaultImage = mainImg
const defaultTheme = '#fff3e9'

const FieldOfOperation = () => {
  const navigate = useNavigate()
  const [theme, setTheme] = useState(defaultTheme)
  const [displayImage, setDisplayImage] = useState(defaultImage)

  const handleMouseEnter = ({ color, image }) => {
    setDisplayImage(image)
    setTheme(color)
  }

  const handleMouseLeave = () => {
    setDisplayImage(defaultImage)
    setTheme(defaultTheme)
  }

  return (
    <Wrapper>
      <div className="field-of-operation">
        <div className="sm-screen-container">
          {fieldOfOperationData.map((field, index) => {
            const { title, description, url, themeColor, image } = field
            return (
              <div
                key={index}
                className="single-field"
                style={{ backgroundColor: themeColor }}
              >
                <div className="field-img-container">
                  <img src={image} alt="img-1" />
                </div>

                <div className="section-center" onClick={() => navigate(url)}>
                  <div className="extended-line"></div>

                  <div className="field-text-container">
                    <div className="field-title">{title} </div>
                    <p className="field-desc">{description}</p>
                  </div>

                  <div className="text-btn">learn more</div>
                </div>
              </div>
            )
          })}
        </div>

        <div className="bg-screen-container" style={{ backgroundColor: theme }}>
          <div className="section section-center">
            <div className="bg-img-container">
              <img src={displayImage} alt="main-img" />
            </div>
            <div className="fields">
              {fieldOfOperationData.map((field, index) => {
                const { title, description, url, themeColor, image } = field
                return (
                  <div
                    key={index}
                    className="field"
                    onMouseEnter={() =>
                      handleMouseEnter({ color: themeColor, image })
                    }
                    onMouseLeave={handleMouseLeave}
                    onClick={() => navigate(url)}
                  >
                    <div className="extended-line"></div>

                    <div className="field-text-container">
                      <div className="field-title">{title} </div>
                      <p className="field-desc">{description}</p>
                    </div>

                    <div className="text-btn">learn more</div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .single-field {
    padding-bottom: 2rem;
    cursor: pointer;
  }

  .field-img-container {
    cursor: default;
  }

  .extended-line {
    height: 2px;
    background: var(--black);
    max-width: 35rem;
    margin: 1rem 0;
    transition: var(--transition);
  }
  .field-title {
    font-size: 1.25rem;
    text-transform: capitalize;
  }

  .bg-screen-container {
    transition: var(--transition);
    display: none;
  }

  .field-desc {
    text-align: justify;
  }

  @media (min-width: 900px) {
    .sm-screen-container {
      display: none;
    }

    .bg-screen-container {
      display: block;
    }
  }

  //big screen content styling

  .bg-screen-container {
    padding: 2rem 0;
  }

  .bg-img-container {
    height: 60vh;
    max-height: 25rem;
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
    }
  }

  .fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    margin-top: 2rem;
    height: 14rem;

    .field {
      cursor: pointer;
      transition: var(--transition);

      &:hover {
        margin-top: -1rem;

        .extended-line {
          height: 4px;
        }
      }
    }
  }
`

export default FieldOfOperation
