import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {
  CopyrightPage,
  FAQsPage,
  ErrorPage,
  GetInTouchPage,
  LandingPage,
  PortfolioPage,
  PrivacyPolicyPage,
  TeamPage,
  TermsPage,
  AboutPage,
} from './pages'
import { Footer, Navbar, ScrollToTop } from './components'
import MissionPage from './pages/MissionPage'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/about/*" element={<AboutPage />} />
        <Route path="/our-mission" element={<MissionPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/portfolio" element={<PortfolioPage />} />
        <Route path="/get-in-touch" element={<GetInTouchPage />} />
        <Route path="/faqs" element={<FAQsPage />} />
        <Route path="/privacy" element={<PrivacyPolicyPage />} />
        <Route path="/copyright" element={<CopyrightPage />} />
        <Route path="/terms" element={<TermsPage />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App
